import { createStore, Store, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { RootAction } from 'actions/ActionTypes';
import { LoginState } from 'reducers/login/LoginState';
import loginReducer from 'reducers/login/LoginReducer';
import {
	ForgotPasswordState,
	forgotPasswordReducer
} from '@spike/forgot-password-action';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { SignUpState } from 'reducers/signUp/SignUpState';
import signUpReducer from 'reducers/signUp/SignUpReducer';
import { ResetPasswordState } from 'reducers/resetPassword/ResetPasswordState';
import resetPasswordReducer from 'reducers/resetPassword/ResetPasswordReducer';
import { ActivateAccountState } from 'reducers/activateAccount/ActivateAccountState';
import activateAccountReducer from 'reducers/activateAccount/ActivateAccountReducer';
import { BusinessSettingsSidebarState } from 'reducers/businessSettings/BusinessSettingsSidebarState';
import BusinessSettingsSidebarReducer from 'reducers/businessSettings/BusinessSettingsSidebarReducer';
import { MyAccountSidebarState } from 'reducers/myAccount/MyAccountSidebarState';
import MyAccountSidebarReducer from 'reducers/myAccount/MyAccountSidebarReducer';
import {
	MarketplaceState,
	marketplaceReducer
} from '@spike/marketplace-action';
import { MasterDataState, masterDataReducer } from '@spike/masterdata-action';
import { ServicesState } from 'reducers/services/ServicesState';
import { servicesReducer } from 'reducers/services/ServicesReducer';
import {
	AddOnServicesState,
	addOnServicesReducer
} from '@spike/addon-services-action';
import { StaffState } from 'reducers/staff/StaffState';
import { staffReducer } from 'reducers/staff/StaffReducer';
import {
	AppointmentsState,
	appointmentsReducer,
	BookingsState,
	bookingsReducer
} from '@spike/bookings-action';
import { NewBookingsState } from 'reducers/newBookings/NewBookingsState';
import { newBookingsReducer } from 'reducers/newBookings/NewBookingsReducer';
import { InvoicesState, invoicesReducer } from '@spike/invoices-action';
import { ReportsState } from 'reducers/reports/ReportsState';
import { reportsReducer } from 'reducers/reports/ReportsReducer';
import { ClientsState, clientsReducer } from '@spike/clients-action';
import { PetsState, petsReducer } from '@spike/pets-action';
import {
	AvailableStaffState,
	availableStaffReducer
} from '@spike/available-staff-action';
import { AvailabilityState } from 'reducers/availability/AvailabilityState';
import { GlobalSearchState } from 'reducers/globalSearch/GlobalSearchState';
import { availabilityReducer } from 'reducers/availability/AvailabilityReducer';
import {
	NotificationsState,
	notificationsReducer,
	notificationsMiddleware
} from '@spike/notifications-action';
import { NewFeaturesState } from 'reducers/newFeatures/NewFeaturesState';
import { newFeaturesReducer } from 'reducers/newFeatures/NewFeaturesReducer';
import { ProductsState, productsReducer } from '@spike/products-action';
import { PosState, posReducer } from '@spike/pos-action';
import { ProductCategoriesState } from 'reducers/productCategories/ProductCategoriesState';
import { productCategoriesReducer } from 'reducers/productCategories/ProductCategoriesReducer';
import { ProductVendorsState } from 'reducers/productVendors/ProductVendorsState';
import { productVendorsReducer } from 'reducers/productVendors/ProductVendorsReducer';
import { VetsState, vetsReducer } from '@spike/vets-action';
import { PaymentsState, paymentsReducer } from '@spike/payments-action';
import { AgreementsState } from 'reducers/agreements/AgreementsState';
import { agreementsReducer } from 'reducers/agreements/AgreementsReducer';
import { TerminalsState, terminalsReducer } from '@spike/terminals-action';
import { RevenuesState } from 'reducers/revenues/RevenuesState';
import { revenuesReducer } from 'reducers/revenues/RevenuesReducer';
import { UsersState } from 'reducers/users/UsersState';
import { usersReducer } from 'reducers/users/UsersReducer';
import { AutocompleteState } from 'reducers/autocomplete/AutocompleteState';
import { autocompleteReducer } from 'reducers/autocomplete/AutocompleteReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import globalSearchReducer from 'reducers/globalSearch/GlobalSearchReducer';
import onboardingStepsReducer from 'reducers/onboardingSettings/onboardingStepsReducer';
import { OnboardingStepsState } from 'reducers/onboardingSettings/onboardingStepsState';
import { NotificationBellState } from 'reducers/notificationBell/NotificationBellState';
import notificationBellReducer from 'reducers/notificationBell/NotificationBellReducer';
import smsCampaignsReducer from 'reducers/smsCampaigns/SMSCampaignsReducer';
import { SMSCampaignsState } from 'reducers/smsCampaigns/SMSCampaignsState';
import twoWaySmsReducer from 'reducers/twowaysms/TwoWaySmsReducer';
import { TwoWaySmsState } from 'reducers/twowaysms/TwoWaySmsState';
import { BulkUploadState } from './reducers/bulkUpload/BulkUploadState';
import bulkUploadReducer from './reducers/bulkUpload/BulkUploadReducer';
import { SubscriptionsState } from 'reducers/subscriptions/SubscriptionsState';
import subscriptionsReducer from 'reducers/subscriptions/SubscriptionsReducer';
import userNotificationsReducer from 'reducers/userNotifications/UserNotificationsReducer';
import { UserNotificationsState } from 'reducers/userNotifications/UserNotificationsState';
import menuReducer from 'reducers/menu/MenuReducer';
import { MenuState } from 'reducers/menu/MenuState';
import { PetTypeStatusState } from 'reducers/businessSettings/PetTypeStatusState';
import BusinessSettingsReducer from 'reducers/businessSettings/BusinessSettingsReducer';
import { TipsReportState } from "reducers/tipsReport/TipsReportState";
import { TipsReportReducer } from "reducers/tipsReport/TipsReportReducer";
import { newBookingsReducer as newBookingReducer } from '@spike/new-bookings-action';
import { NewBookingsState as NewBookingState } from '@spike/new-bookings-action';
import { blockCalendarReducer, BlockCalendarState } from '@spike/block-calendar-action';
import { CommissionsState } from '@spike/commissions-action';
import { commissionsReducer } from '@spike/commissions-action';
import {OLBStatusState} from "./reducers/businessSettings/OLBStatusState";
import {OLBStatusReducer} from "./reducers/businessSettings/OLBStatusReducer";

export const history = createBrowserHistory();

export interface RootState {
	readonly onboardingSteps: OnboardingStepsState;
	readonly login: LoginState;
	readonly forgotPassword: ForgotPasswordState;
	readonly signUp: SignUpState;
	readonly resetPassword: ResetPasswordState;
	readonly activateAccount: ActivateAccountState;
	readonly BusinessSettingsSidebar: BusinessSettingsSidebarState;
	readonly MyAccountSidebar: MyAccountSidebarState;
	readonly marketplace: MarketplaceState;
	readonly masterData: MasterDataState;
	readonly services: ServicesState;
	readonly petTypeStatus: PetTypeStatusState;
	readonly addOnServices: AddOnServicesState;
	readonly staff: StaffState;
	readonly appointments: AppointmentsState;
	readonly bookings: BookingsState;
	readonly globalSearch: GlobalSearchState;
	readonly newBookingsOld: NewBookingsState;
	readonly invoices: InvoicesState;
	readonly reports: ReportsState;
	readonly clients: ClientsState;
	readonly pets: PetsState;
	readonly olbStatus: OLBStatusState;
	readonly availableStaff: AvailableStaffState;
	readonly availability: AvailabilityState;
	readonly revenues: RevenuesState;
	readonly users: UsersState;
	readonly autocomplete: AutocompleteState;
	readonly notifications: NotificationsState;
	readonly newFeatures: NewFeaturesState;
	readonly products: ProductsState;
	readonly pos: PosState;
	readonly productCategories: ProductCategoriesState;
	readonly productVendors: ProductVendorsState;
	readonly vets: VetsState;
	readonly payments: PaymentsState;
	readonly terminals: TerminalsState;
	readonly notificationBell: NotificationBellState;
	readonly userNotifications: UserNotificationsState;
	readonly twoWaySms: TwoWaySmsState;
	readonly smsCampaigns: SMSCampaignsState;
	readonly agreements: AgreementsState;
	readonly subscriptions: SubscriptionsState;
	readonly bulkUpload: BulkUploadState;
	readonly tipsReport: TipsReportState;
	readonly menu: MenuState;
	readonly newBookings: NewBookingState;
	readonly blockCalendar: BlockCalendarState;
	readonly commissions: CommissionsState;
}
// eslint-disable-next-line
const createRootReducer = (history: any) =>
	combineReducers({
		router: connectRouter(history),
		login: loginReducer,
		forgotPassword: forgotPasswordReducer,
		signUp: signUpReducer,
		resetPassword: resetPasswordReducer,
		activateAccount: activateAccountReducer,
		BusinessSettingsSidebar: BusinessSettingsSidebarReducer,
		MyAccountSidebar: MyAccountSidebarReducer,
		onboardingSteps: onboardingStepsReducer,
		marketplace: marketplaceReducer,
		masterData: masterDataReducer,
		services: servicesReducer,
		petTypeStatus: BusinessSettingsReducer,
		addOnServices: addOnServicesReducer,
		staff: staffReducer,
		appointments: appointmentsReducer,
		globalSearch: globalSearchReducer,
		bookings: bookingsReducer,
		newBookingsOld: newBookingsReducer,
		invoices: invoicesReducer,
		reports: reportsReducer,
		clients: clientsReducer,
		pets: petsReducer,
		olbStatus: OLBStatusReducer,
		availableStaff: availableStaffReducer,
		availability: availabilityReducer,
		revenues: revenuesReducer,
		users: usersReducer,
		autocomplete: autocompleteReducer,
		notifications: notificationsReducer,
		newFeatures: newFeaturesReducer,
		products: productsReducer,
		pos: posReducer,
		productCategories: productCategoriesReducer,
		productVendors: productVendorsReducer,
		vets: vetsReducer,
		payments: paymentsReducer,
		terminals: terminalsReducer,
		notificationBell: notificationBellReducer,
		userNotifications: userNotificationsReducer,
		twoWaySms: twoWaySmsReducer,
		smsCampaigns: smsCampaignsReducer,
		agreements: agreementsReducer,
		subscriptions: subscriptionsReducer,
		bulkUpload: bulkUploadReducer,
		menu: menuReducer,
		commissions: commissionsReducer,
    	tipsReport: TipsReportReducer,
		newBookings: newBookingReducer,
		blockCalendar: blockCalendarReducer
	});

const middleware = applyMiddleware(
	thunk,
	routerMiddleware(history),
	notificationsMiddleware
);

const composeEnhancers = composeWithDevTools({});

export const store: Store<RootState, RootAction> = createStore(
	createRootReducer(history),
	composeEnhancers(middleware)
);

export default store;
