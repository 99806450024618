import { FunctionComponent, useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import {
  resendReportThunk,
  saveDentalReportThunk,
  saveGroomingReportThunk,
  skipReportThunk,
} from "actions/reports/ReportsActions";
import GroomingReport from "model/GroomerReport";
import { ReportsStatus } from "reducers/reports/ReportsState";
import { GroomingReportComponent } from "./GroomingReport/GroomingReportComponent";
import { ViewReport } from "./ViewReport";
import DentalReportComponent from "./DentalReport/DentalReportComponent";
import DentalReport from "model/DentalReport";

interface Props {
  appointmentId: number;
  businessAreaId: string;
  emailConfigured?: boolean;
  savePDF?: boolean;
  onBack: () => void;
  onClose: () => void;
  onView: () => void;
  declined: boolean;
  sent?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            height: '100%',
            width: '100%',
            justifyContent: 'center'
        }
    })
);

export const EditReport: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [saving, setSaving] = useState(false);
  const [savingPDF, setSavingPDF] = useState(false);
  const [showEditGrooming, setShowEditGrooming] = useState(false);
  const [showViewGrooming, setShowViewGrooming] = useState(false);
  const [showEditDental, setShowEditDental] = useState(false);
  const [showViewDental, setShowViewDental] = useState(false);
  const [reportToSent, setReportToSent] = useState(false);

  const groomingReport = useSelector<RootState, GroomingReport | undefined>((state) => state.reports.groomingReport);
  const dentalReport = useSelector<RootState, DentalReport | undefined>((state) => state.reports.dentalReport);

  const reportStatus = useSelector<RootState, ReportsStatus>((state) => state.reports.status);

  useEffect(() => {
    if ([ReportsStatus.GetDentalByAppointmentSuccess].includes(reportStatus)) {
      setSaving(false);
      setShowEditDental(true);
    } else if ([ReportsStatus.GetGroomingByAppointmentSuccess].includes(reportStatus)) {
      setSaving(false);
      setShowEditGrooming(true);
    } else if (([ReportsStatus.SaveDentalSuccess].includes(reportStatus)) && (savingPDF)) {
      props.onView();
    } else if ([ReportsStatus.SaveDentalSuccess].includes(reportStatus)) {
      closeReportHandler();
      setSaving(false);
    } else if (([ReportsStatus.SaveGroomingSuccess].includes(reportStatus)) && (savingPDF)) {
      props.onView();
    } else if ([ReportsStatus.SaveGroomingSuccess].includes(reportStatus)) {
      closeReportHandler();
      setSaving(false);
      if (reportToSent) {
        setSaving(true);
        dispatch(resendReportThunk(props.appointmentId));
      }
    } else if ([ReportsStatus.ResendReportSuccess].includes(reportStatus)) {
      setSaving(false);
      setReportToSent(false);
    } else if ([ReportsStatus.Error].includes(reportStatus)) {
      setSaving(false);
    }
  }, [reportStatus]);

  const saveGroomingReportHandler = (report: GroomingReport) => {
    setSaving(true);
    dispatch(saveGroomingReportThunk(report, groomingReport?.images));
  };

  const saveDentalReportHandler = (report: DentalReport) => {
    setSaving(true);
    dispatch(saveDentalReportThunk(report));
  };

  const sendDentalReportHandler = (report: DentalReport) => {
    setSaving(true);
    setReportToSent(true);
    dispatch(saveDentalReportThunk(report));
  };

  const skipReportHandler = () => {
    dispatch(skipReportThunk());
  }

  const sendReportHandler = (report: GroomingReport) => {
    setSaving(true);
    setReportToSent(true);
    dispatch(saveGroomingReportThunk(report, groomingReport?.images));
    dispatch(resendReportThunk(props.appointmentId));
  };


  const editGroomingHandler = () => {
    setShowViewGrooming(false);
    setShowEditGrooming(true);
  }

  const editDentalHandler = () => {
    setShowViewDental(false);
    setShowEditDental(true);
  }

  const backHandler = () => {
    props.onBack();
  }

  const closeReportHandler = () => {
    props.onClose();
  }

  const editReportGroomingView = (
    <GroomingReportComponent
      groomingReport={groomingReport!}
      singleImage={false}
      onSave={saveGroomingReportHandler}
      onSend={sendReportHandler}
      back={backHandler}
      saving={saving}
      declined={props.declined}
      emailConfigured={props.emailConfigured}
      sent={props.sent}
    />
  );

  const editReportDentalView = (
    <DentalReportComponent
      dentalReport={dentalReport!}
      singleImage={false}
      onSave={saveDentalReportHandler}
      onSend={sendDentalReportHandler}
      onSkip={skipReportHandler}
      back={backHandler}
      saving={saving}
      declined={props.declined}
      emailConfigured={props.emailConfigured}
      sent={props.sent}
    />
  );

  const viewReportView = (
    <ViewReport
      appointmentId={props.appointmentId!}
      businessAreaId={props.businessAreaId!}
      onBack={backHandler}
      onClose={closeReportHandler}
      emailConfigured={props.emailConfigured}
      onEditGrooming={editGroomingHandler}
      onEditDental={editDentalHandler}
    />
  );

  return (
    <Box className={classes.container}>
      {showEditGrooming && editReportGroomingView}
      {showViewGrooming && viewReportView}

      {showEditDental && editReportDentalView}
      {showViewDental && viewReportView}
    </Box>
  );
};

export default EditReport;
