import {
    ReportsAction,
    REPORTS_RESET_ACTION_TYPE,
    REPORTS_CLEANUP_DENTAL_ACTION_TYPE,
    REPORTS_SAVE_DENTAL_START_ACTION_TYPE,
    REPORTS_SAVE_DENTAL_SUCCESS_ACTION_TYPE,
    REPORTS_GET_DENTAL_BY_APPOINTMENT_START_ACTION_TYPE,
    REPORTS_GET_DENTAL_BY_APPOINTMENT_SUCCESS_ACTION_TYPE,
    REPORTS_GET_DENTAL_PDF_BY_APPOINTMENT_START_ACTION_TYPE,
    REPORTS_GET_DENTAL_PDF_BY_APPOINTMENT_SUCCESS_ACTION_TYPE,
    REPORTS_SAVE_GROOMING_START_ACTION_TYPE,
    REPORTS_SAVE_GROOMING_SUCCESS_ACTION_TYPE,
    REPORTS_GET_GROOMING_BY_APPOINTMENT_START_ACTION_TYPE,
    REPORTS_GET_GROOMING_BY_APPOINTMENT_SUCCESS_ACTION_TYPE,
    REPORTS_GET_GROOMING_PDF_BY_APPOINTMENT_START_ACTION_TYPE,
    REPORTS_GET_GROOMING_PDF_BY_APPOINTMENT_SUCCESS_ACTION_TYPE,
    REPORTS_RESEND_REPORT_START_ACTION_TYPE,
    REPORTS_RESEND_REPORT_SUCCESS_ACTION_TYPE,
    REPORTS_ERROR_ACTION_TYPE,
    REPORTS_SKIP_ACTION_TYPE
} from './ReportsActionsTypes';
import { ThunkAction } from 'redux-thunk';
import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ReportsState } from 'reducers/reports/ReportsState';
import ApiClient, {
    createMultipartTokenConfig,
    createTokenConfig,
    isCancelled
} from 'api/ApiClient';
import { v4 as uuid } from 'uuid';
import { NotificationsAction } from '@spike/notifications-action';
import { showError, showSuccess } from '@spike/notifications-action';
import store from 'store';
import { DentalReport } from 'model/DentalReport';
import { GroomingReport } from 'model/GroomerReport';
import { Appointment } from '@spike/appointment-model';
import { convertDentalReport, convertGroomingReport } from './ReportsConverter';
import { getAppointment as getRestAppointment } from '@spike/bookings-action';
import { DentalReportDto, GroomingReportDto } from './ReportsDtos';
import { serialize } from 'object-to-formdata';
import { createWrapper } from 'api/ApiClient';

const dentalBaseUrl = 'report_dentals';
const dentalReportByAppointmentUrl = 'dental_report';

const groomingBaseUrl = 'report_groomings';
const groomingReportByAppointmentUrl = 'grooming_report';

const resendUrl = '/appointment/resend_report';

const HTTP_STATUS_422_UNPROCESSABLE_ENTITY = 422;

const config: AxiosRequestConfig = {
    baseURL: `${process.env.REACT_APP_HOST_URL}${process.env.REACT_APP_WEB_BASE_URL}`,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
};

const WebClient: AxiosInstance = Axios.create(config);

export const getDentalReportByAppointmentIdThunk = (
    appointmentId: number
): ThunkAction<
    void,
    ReportsState,
    null,
    ReportsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(getDentalByAppointmentStart());

        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${dentalReportByAppointmentUrl}/${appointmentId}?marketplace_id=${marketplaceId}`;

        try {
            const appointment = await getAppointment(appointmentId);

            try {
                const response: AxiosResponse<DentalReportDto> =
                    await ApiClient.get(
                        url,
                        createTokenConfig(store.getState().login.auth.token!)
                    );

                dispatch(
                    getDentalByAppointmentSuccess(appointment, response.data)
                );
            } catch (apiError: any) {
                if (
                    apiError.response &&
                    apiError.response.status ===
                        HTTP_STATUS_422_UNPROCESSABLE_ENTITY
                ) {
                    dispatch(getDentalByAppointmentSuccess(appointment));
                } else {
                    dispatch(error());
                    dispatch(
                        showError('Error getting dental report by appointment.')
                    );
                    console.error(apiError);
                }
            }
        } catch (apiError: any) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                dispatch(
                    showError("Error getting dental report's appointment.")
                );
            }
        }
    };
};

export const getGroomingReportByAppointmentIdThunk = (
    appointmentId: number
): ThunkAction<
    void,
    ReportsState,
    null,
    ReportsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(getGroomingByAppointmentStart());
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${groomingReportByAppointmentUrl}/${appointmentId}?marketplace_id=${marketplaceId}`;

        try {
            const appointment = await getAppointment(appointmentId);

            try {
                const response: AxiosResponse<GroomingReportDto> =
                    await ApiClient.get(
                        url,
                        createTokenConfig(store.getState().login.auth.token!)
                    );

                dispatch(
                    getGroomingByAppointmentSuccess(appointment, response.data)
                );
            } catch (apiError: any) {
                if (
                    apiError.response &&
                    apiError.response.status ===
                        HTTP_STATUS_422_UNPROCESSABLE_ENTITY
                ) {
                    dispatch(getGroomingByAppointmentSuccess(appointment));
                } else {
                    dispatch(error());
                    dispatch(
                        showError(
                            'Error getting grooming report by appointment.'
                        )
                    );
                    console.error(apiError);
                }
            }
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                dispatch(
                    showError("Error getting grooming report's appointment.")
                );
            }
        }
    };
};

export const skipReportThunk = (): ThunkAction<
    void,
    ReportsState,
    null,
    ReportsAction
> => {
    return async dispatch => {
        dispatch(skipDentalReport());
    };
};

export const saveDentalReportThunk = (
    dentalReport: DentalReport
): ThunkAction<
    void,
    ReportsState,
    null,
    ReportsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(saveDentalStart());

        const marketplaceId = store.getState().login.auth.marketplaceId;
        const dentalUpdateUrl = `${dentalBaseUrl}/${dentalReport.id}?marketplace_id=${marketplaceId}`;
        const dentalCreateUrl = `${dentalBaseUrl}?marketplace_id=${marketplaceId}`;

        const dentalSave = dentalReport.id ? ApiClient.patch : ApiClient.post;
        const dentalSaveUrl = dentalReport.id
            ? dentalUpdateUrl
            : dentalCreateUrl;

        const dentalReportRequest = {
            id: dentalReport.id,
            appointment_id: dentalReport.appointment.id,
            uuid: dentalReport.uuid,
            procedure_records: JSON.stringify(dentalReport.procedureRecords),
            homecare: JSON.stringify(dentalReport.homecare),
            recommendation: JSON.stringify(dentalReport.recommendations),
            tooth_abnormalities: JSON.stringify(dentalReport.toothAbnomalities),
            periodontal_findings: JSON.stringify(
                dentalReport.periodontalFindings
            ),
            remarks: dentalReport.remarks,
            calculus: dentalReport.calculus,
            plaque: dentalReport.plaque,
            next_dental_date: dentalReport.nextDentalDate
                ? dentalReport.nextDentalDate
                : null,
            declined: dentalReport.declined
        };

        let formData = new FormData();

        const options = {
            indices: false,
            nullsAsUndefineds: false,
            booleansAsIntegers: false,
            allowEmptyArrays: false
        };

        formData = serialize(
            dentalReportRequest,
            options,
            formData,
            'report_dental'
        );

        const [beforeImageFile, afterImageFile] = [
            ...dentalReport.imageFilesToUpload
        ];

        if (beforeImageFile) {
            formData.append('report_dental[before_image]', beforeImageFile);
        }

        if (afterImageFile) {
            formData.append('report_dental[after_image]', afterImageFile);
        }

        try {
            const response: AxiosResponse<DentalReportDto> = await dentalSave(
                dentalSaveUrl,
                formData,
                createMultipartTokenConfig(store.getState().login.auth.token!)
            );
            try {
                const appointment = await getAppointment(
                    response.data.appointment_id
                );
                dispatch(saveDentalSuccess(response.data, appointment));
            } catch (apiError) {
                dispatch(
                    showError("Error getting dental report's appointment.")
                );
                console.error(apiError);
            }
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                dispatch(showError('Error saving dental report.'));
            }
        }
    };
};

export const saveGroomingReportThunk = (
    groomingReport: GroomingReport,
    initialImages?: any
): ThunkAction<
    void,
    ReportsState,
    null,
    ReportsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(saveGroomingStart());

        const marketplaceId = store.getState().login.auth.marketplaceId;
        const groomingUpdateUrl = `${groomingBaseUrl}/${groomingReport.id}?marketplace_id=${marketplaceId}`;
        const groomingCreateUrl = `${groomingBaseUrl}?marketplace_id=${marketplaceId}`;

        const groomingSave = groomingReport.id
            ? ApiClient.patch
            : ApiClient.post;
        const groomingSaveUrl = groomingReport.id
            ? groomingUpdateUrl
            : groomingCreateUrl;

        const groomingReportRequest = {
            id: groomingReport.id,
            appointment_id: groomingReport.appointment.id,
            uuid: groomingReport.uuid,
            skin: JSON.stringify(groomingReport.skin),
            teeth: JSON.stringify(groomingReport.teeth),
            nails: JSON.stringify(groomingReport.nails),
            coat: JSON.stringify(groomingReport.coat),
            ears: JSON.stringify(groomingReport.ears),
            eyes: JSON.stringify(groomingReport.eyes),
            behavior: JSON.stringify(groomingReport.behavior),
            findings: JSON.stringify(groomingReport.findings),
            recommendation: JSON.stringify(groomingReport.recommendations),
            remarks: groomingReport.remarks,
            next_visit: groomingReport.nextVisit,
            declined: groomingReport.declined
        };

        let formData = new FormData();

        const options = {
            indices: false,
            nullsAsUndefineds: false,
            booleansAsIntegers: false,
            allowEmptyArrays: false
        };

        formData = serialize(
            groomingReportRequest,
            options,
            formData,
            'report_grooming'
        );

        const [beforeImageFileToUpdate, afterImageFileToUpdate] = [
            ...groomingReport.imageFilesToUpload
        ];

        if (beforeImageFileToUpdate) {
            formData.append(
                'report_grooming[before_image]',
                beforeImageFileToUpdate
            );
        } else if (
            initialImages &&
            initialImages[0] &&
            !groomingReport.images[0]
        ) {
            formData.append('report_grooming[remove_before_image]', 'true');
        }

        if (afterImageFileToUpdate) {
            formData.append(
                'report_grooming[after_image]',
                afterImageFileToUpdate
            );
        } else if (
            initialImages &&
            initialImages[1] &&
            !groomingReport.images[1]
        ) {
            formData.append('report_grooming[remove_after_image]', 'true');
        }

        try {
            const response: AxiosResponse<GroomingReportDto> =
                await groomingSave(
                    groomingSaveUrl,
                    formData,
                    createMultipartTokenConfig(
                        store.getState().login.auth.token!
                    )
                );

            try {
                const appointment = await getAppointment(
                    response.data.appointment_id
                );
                dispatch(saveGroomingSuccess(response.data, appointment));
            } catch (apiError) {
                if (!isCancelled(apiError)) {
                    dispatch(
                        showError(
                            "Error getting grooming report's appointment."
                        )
                    );
                }
            }
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                dispatch(showError('Error saving grooming report.'));
            }
        }
    };
};

export const resendReportThunk = (
	appointmentId: number
): ThunkAction<void, ReportsState, null, ReportsAction | NotificationsAction> => {
	return async (dispatch) => {
		dispatch(resendReportStart());
		const marketplaceId = store.getState().login.auth.marketplaceId;
		const url = `${resendUrl}/${appointmentId}?marketplace_id=${marketplaceId}`;

		try {
			await ApiClient.post(
				url,
				undefined,
				createTokenConfig(store.getState().login.auth.token!)
			);
			dispatch(resendReportSuccess());
			dispatch(showSuccess("Report sent!"));
		} catch (apiError) {
			if (!isCancelled(apiError)) {
				dispatch(error());
				dispatch(showError("Error sending report."));
			}
		}
	};
};

export const getDentalReportPDFByAppointmentIdThunk = (
    appointmentId: number
): ThunkAction<
    void,
    ReportsState,
    null,
    ReportsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(getDentalPDFByAppointmentStart());
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `booking/${dentalReportByAppointmentUrl}/${appointmentId}/pdf?access_token=${
            store.getState().login.auth.token
        }&marketplace_id=${marketplaceId}`;

        try {
            const response = await WebClient.get(url, {
                responseType: 'blob'
            });
            const blob = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = blob;
            link.setAttribute('download', 'Report.pdf');
            document.body.appendChild(link);
            link.click();

            dispatch(getDentalPDFByAppointmentSuccess(response.data));
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                dispatch(showError('Error getting dental report PDF.'));
            }
        }
    };
};

export const getGroomingReportPDFByAppointmentIdThunk = (
    appointmentId: number
): ThunkAction<
    void,
    ReportsState,
    null,
    ReportsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(getGroomingPDFByAppointmentStart());
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `booking/${groomingReportByAppointmentUrl}/${appointmentId}/pdf?access_token=${
            store.getState().login.auth.token
        }&marketplace_id=${marketplaceId}`;

        try {
            const response = await WebClient.get(url, {
                responseType: 'blob'
            });
            const blob = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = blob;
            link.setAttribute('download', 'Report.pdf');
            document.body.appendChild(link);
            link.click();
            dispatch(getGroomingPDFByAppointmentSuccess(response.data));
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                dispatch(showError('Error getting grooming report PDF.'));
            }
        }
    };
};

const getDentalPDFByAppointmentStart = (): ReportsAction => {
    return {
        type: REPORTS_GET_DENTAL_PDF_BY_APPOINTMENT_START_ACTION_TYPE
    };
};

const getGroomingPDFByAppointmentStart = (): ReportsAction => {
    return {
        type: REPORTS_GET_GROOMING_PDF_BY_APPOINTMENT_START_ACTION_TYPE
    };
};

export const reset = (): ReportsAction => {
    return {
        type: REPORTS_RESET_ACTION_TYPE
    };
};

export const cleanupDental = (): ReportsAction => {
    return {
        type: REPORTS_CLEANUP_DENTAL_ACTION_TYPE
    };
};

const saveDentalStart = (): ReportsAction => {
    return {
        type: REPORTS_SAVE_DENTAL_START_ACTION_TYPE
    };
};

export const saveDentalSuccess = (
    dentalReportDto: DentalReportDto,
    appointment: Appointment
): ReportsAction => {
    const dentalReport = convertDentalReport(dentalReportDto, appointment);
    return {
        type: REPORTS_SAVE_DENTAL_SUCCESS_ACTION_TYPE,
        payload: {
            dentalReport
        }
    };
};

export const skipDentalReport = (): ReportsAction => {
    return {
        type: REPORTS_SKIP_ACTION_TYPE
    };
};

const saveGroomingStart = (): ReportsAction => {
    return {
        type: REPORTS_SAVE_GROOMING_START_ACTION_TYPE
    };
};

export const saveGroomingSuccess = (
    groomingReportDto: GroomingReportDto,
    appointment: Appointment
): ReportsAction => {
    const groomingReport = convertGroomingReport(
        groomingReportDto,
        appointment
    );
    return {
        type: REPORTS_SAVE_GROOMING_SUCCESS_ACTION_TYPE,
        payload: {
            groomingReport
        }
    };
};

const getDentalByAppointmentStart = (): ReportsAction => {
    return {
        type: REPORTS_GET_DENTAL_BY_APPOINTMENT_START_ACTION_TYPE
    };
};

export const getDentalByAppointmentSuccess = (
    appointment: Appointment,
    dentalReportDto?: DentalReportDto
): ReportsAction => {
    const dentalReport = dentalReportDto
        ? convertDentalReport(dentalReportDto, appointment)
        : createNewDentalReport(appointment);
    return {
        type: REPORTS_GET_DENTAL_BY_APPOINTMENT_SUCCESS_ACTION_TYPE,
        payload: {
            dentalReport
        }
    };
};

const getGroomingByAppointmentStart = (): ReportsAction => {
    return {
        type: REPORTS_GET_GROOMING_BY_APPOINTMENT_START_ACTION_TYPE
    };
};

export const getGroomingByAppointmentSuccess = (
    appointment: Appointment,
    groomingReportDto?: GroomingReportDto
): ReportsAction => {
    const groomingReport = groomingReportDto
        ? convertGroomingReport(groomingReportDto, appointment)
        : createNewGroomingReport(appointment);
    return {
        type: REPORTS_GET_GROOMING_BY_APPOINTMENT_SUCCESS_ACTION_TYPE,
        payload: {
            groomingReport
        }
    };
};

export const getDentalPDFByAppointmentSuccess = (
    dentalReportPDF: any
): ReportsAction => {
    return {
        type: REPORTS_GET_DENTAL_PDF_BY_APPOINTMENT_SUCCESS_ACTION_TYPE,
        payload: {
            dentalReportPDF
        }
    };
};

export const getGroomingPDFByAppointmentSuccess = (
    groomingReportPDF: any
): ReportsAction => {
    return {
        type: REPORTS_GET_GROOMING_PDF_BY_APPOINTMENT_SUCCESS_ACTION_TYPE,
        payload: {
            groomingReportPDF
        }
    };
};

const resendReportStart = (): ReportsAction => {
    return {
        type: REPORTS_RESEND_REPORT_START_ACTION_TYPE
    };
};

const resendReportSuccess = (): ReportsAction => {
    return {
        type: REPORTS_RESEND_REPORT_SUCCESS_ACTION_TYPE
    };
};

const error = (): ReportsAction => {
    return {
        type: REPORTS_ERROR_ACTION_TYPE
    };
};

const getAppointment = (appointmentId: number) => {
    return getRestAppointment(createWrapper(store.getState()), appointmentId);
};

const createNewDentalReport = (appointment: Appointment) => {
    return {
        uuid: uuid(),
        appointment: { ...appointment },
        procedureRecords: [],
        remarks: '',
        calculus: 0,
        plaque: 0,
        homecare: [],
        recommendations: [],
        images: [],
        imageFilesToUpload: [],
        nextDentalDate: new Date(),
        toothAbnomalities: [],
        periodontalFindings: []
    };
};

const createNewGroomingReport = (appointment: Appointment) => {
    return {
        uuid: uuid(),
        appointment: { ...appointment },
        skin: [],
        teeth: [],
        nails: [],
        coat: [],
        ears: [],
        eyes: [],
        behavior: [],
        findings: [],
        remarks: '',
        images: [],
        imageFilesToUpload: [],
        imagesFilesToUpload: [],
        recommendations: [],
        nextVisit: null
    };
};
